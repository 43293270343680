<template>
  <v-container>

    <div class="contenedor1">


     <div class="izquierda">

    <v-card
    class="card"
    max-width="344"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          BUSCAR POR 
        </div>
        <v-list-item-title class="text-h5 mb-1">
          UNIVERSIDAD
        </v-list-item-title>
      </v-list-item-content>

     <img src="../assets/universidad.jpeg" width="100" height="80">
    </v-list-item>

    <v-card-actions>
      <v-btn
      depressed
      color="#00D590"
      @click="select_universidad()"
    >
      Seleccionar
    </v-btn>
    </v-card-actions>
  </v-card>

     </div>






     <div class="derecha">
    


    <v-card
    class="card1"
    max-width="344"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          BUSCAR POR 
        </div>
        <v-list-item-title class="text-h5 mb-1">
          JUGADOR
        </v-list-item-title>
      </v-list-item-content>

      
      <img src="../assets/jugador.jpeg" width="100" height="80">
    </v-list-item>

    <v-card-actions>
      <v-btn
      depressed
      color="#00D590"
      @click="select_player()"
    >
      Seleccionar
    </v-btn>
    </v-card-actions>
  </v-card>

    </div>
    </div>





    <div class="contenedor2">

      <div class="item" v-if="this.select_uni==1">
          <p> Seleccione la Universidad</p>
          <v-row align="center">
          <v-col
            class="d-flex"
            cols="12"
            sm="6"
          >
            <v-select
              :items="uni_name"
              label="Universidades"
              dense
              solo
              v-model="universidad"
            ></v-select>
          </v-col>
        </v-row>  

        
            <v-btn
              color="error"
              dark
              large
              class="boton"
              @click="seleccionaruniversidad()"
            >
              Buscar
            </v-btn>    
      </div> 

      <div class="item" v-if="this.select_pla==1">

        <p> Seleccione el Jugador</p>
          <v-row align="center">
          <v-col
            class="d-flex"
            cols="12"
            sm="6"
          >
            <v-select
              :items="player_name"
              label="Jugador"
              dense
              v-model="jugador"
              solo
            ></v-select>
          </v-col>
        </v-row>  

       
            <v-btn
              color="error"
              dark
              large
              class="boton"
              @click="seleccionarjugador()"
            >
              Buscar
            </v-btn>  
      </div>
    </div>


    <div class="contenedor3" v-if="this.select_uni==1 && this.unico==0">

     <v-layout wrap justify-space-around>
        <v-flex v-for="image in this.universidades" :key="image.NOMBRE">
          <v-card
          width="300px"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                Universidad
              </div>
              <v-list-item-title class="text-h5 mb-1">
                {{ image.NOMBRE }}
              </v-list-item-title>
              <v-list-item-subtitle> {{ image.DIVISIÓN }} </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
            
            >
            <v-img  :src="image.LOGO"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
               @click="goInfoUni1(image)"
            >
              Hay {{ image.JUGADORES }} jugadores
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-flex>
      </v-layout>

     
    </div>
    

    <div class="contenedor4" v-if="this.select_pla==1 && this.unico==0">

       <v-layout wrap justify-space-around>
        <v-flex v-for="image in this.jugadores" :key="image.NOMBRE">
          <v-card
          width="300px"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                Jugador
              </div>
              <v-list-item-title class="text-h5 mb-1">
                {{ image.NOMBRE }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
            
            >
            <v-img  :src="image.FOTO"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              @click="goInfoPla1(image)"
            >
              Hay {{ image.UNIVERSIDADES }} universidades
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-flex>
      </v-layout>
      
    


    </div>

     <div class="contenedor3" v-if="this.select_uni==1 && this.unico==1">

     <v-layout wrap justify-space-around>
        <v-flex>
          <v-card
          width="300px"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                Universidad
              </div>
              <v-list-item-title class="text-h5 mb-1">
                {{ this.universidades.NOMBRE }}
              </v-list-item-title>
              <v-list-item-subtitle> {{ this.universidades.DIVISIÓN }} </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
            
            >
            <v-img  :src="this.universidades.LOGO"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              @click="goInfoUni()"
            >
              Hay {{ this.universidades.JUGADORES }} jugadores
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-flex>
      </v-layout>

     </div>

    <div class="contenedor4" v-if="this.select_pla==1 && this.unico==1">

       <v-layout wrap justify-space-around>
        <v-flex>
          <v-card
          width="300px"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                Jugador
              </div>
              <v-list-item-title class="text-h5 mb-1">
                {{ this.jugadores.NOMBRE }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
            
            >
            <v-img  :src="this.jugadores.FOTO"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              @click="goInfoPla()"
            >
              Hay {{ this.jugadores.UNIVERSIDADES }} universidades
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-flex>
      </v-layout>
    </div>


  </v-container>
</template>

<script>
const { GoogleSpreadsheet } = require('google-spreadsheet');

  export default {
    name: 'Main',

    data: () => ({
      select_uni:0,
      select_pla:0,
      jugadores: [],
      universidades: [],
      universidades_copia: [],
      jugadores_copia: [],
      uni_name: [],
      player_name:[],
      universidad:'',
      jugador:'',
      filter_player:[],
      filter_uni:[],
      actual_play:[],
      actual_uni:[],
      unico:0,
      val3:null,
      val4:null,
      val5:null,
      val6:null
    }),
    mounted(){
      try{
        this.universidades=this.$route.params.universidades;
        this.jugadores=this.$route.params.jugadores;
        this.universidades_copia=this.universidades;
        this.jugadores_copia=this.jugadores;
        for (let i = 0; i < this.universidades.length; i++){
          this.uni_name.push(this.universidades[i].NOMBRE);
        }


         for (let i = 0; i < this.jugadores.length; i++){
           if (this.jugadores[i].STATUS!=1){
          this.player_name.push(this.jugadores[i].NOMBRE);
           }
        }

        this.uni_name = this.uni_name.sort();
        this.player_name = this.player_name.sort();

      }catch{
      const doc = new GoogleSpreadsheet('1aCjEh_gJ6XgIVaL1LwBO3rPhw4R7xl5uZ1keirRol94');

      const creds = require('../../public/service_account.json'); // the file saved above

      this.start(doc,creds);
      }
    },
    methods: {
      goInfoUni(){
         this.$router.push({ name: "InfoUni", params: {universidades: this.universidades, jugadores: this.jugadores, 
         universidades_copia:this.universidades_copia, jugadores_copia:this.jugadores_copia}})
      },
      goInfoPla(){
         this.$router.push({ name: "InfoPla", params: {universidades: this.universidades, jugadores: this.jugadores, universidades_copia:this.universidades_copia, jugadores_copia:this.jugadores_copia}})
      },
       goInfoUni1(universi){
         this.$router.push({ name: "InfoUni", params: {universidades: universi, jugadores: this.jugadores, universidades_copia:this.universidades_copia, jugadores_copia:this.jugadores_copia}})
      },
      goInfoPla1(jugad){
         this.$router.push({ name: "InfoPla", params: {universidades: this.universidades, jugadores: jugad,universidades_copia:this.universidades_copia, jugadores_copia:this.jugadores_copia}})
      },
      select_universidad(){
        this.select_uni=1;
        this.select_pla=0;
        this.actual_play=[];
        this.actual_uni=[];
        this.unico=0;
        this.jugadores=this.jugadores_copia;
        this.universidades=this.universidades_copia;
      },
      select_player(){
        this.select_uni=0;
        this.select_pla=1;
        this.actual_play=[];
        this.actual_uni=[];
        this.unico=0;
        this.jugadores=this.jugadores_copia;
        this.universidades=this.universidades_copia;
      },
      change_val6(){
        this.val5=null;
        this.val4=null;
        this.val3=null;
      },
      change_val5(){
        this.val6=null;
        this.val4=null;
        this.val3=null;
      },
      change_val4(){
        this.val6=null;
        this.val5=null;
        this.val3=null;
      },
      change_val3(){
        this.val6=null;
        this.val4=null;
        this.val5=null;
      },
      filtrar_universidad(){
      
        this.filter_player=[]
        var uni = []

        for (let i = 0; i < this.universidades.length; i++){
          if(this.universidad==this.universidades[i].NOMBRE){
            uni = this.universidades[i];
          }
        }
        this.actual_uni=uni;

        var A = ["D1", "D2", "NAIA", "JUCO"];
        var B = ["D2", "NAIA", "JUCO"];
        var C = ["NAIA", "JUCO"];
        var D = ["JUCO"];

        var budgetA = ["A", "B", "C", "D"];
        var budgetB = ["B", "C", "D"];
        var budgetC = ["C", "D"];
        var budgetD = ["D"];
             

        for (let i = 0; i < this.jugadores.length; i++){
          var nivel = this.jugadores[i].NIVEL;
          var level = [];
          if (nivel == "A"){
              level = A;
          }
          else if( nivel == "B"){
              level = B;
          }
          else if (nivel == "C"){
              level = C;
          }
          else{
              level = D;
          }

          var budget = [];
           if (this.jugadores[i].BUDGET == "A"){
              budget = budgetA;
          }
          else if( this.jugadores[i].BUDGET == "B"){
              budget = budgetB;
          }
          else if (this.jugadores[i].BUDGET == "C"){
              budget = budgetC;
          }
          else{
              budget = budgetD;
          }

         
          if (level.includes(uni.DIVISIÓN) && budget.includes(uni.BUDGET) && this.jugadores[i].STATUS!=1){
                  var posiciones3 = this.jugadores[i].POSICIONES.split(",");
                  var pos3 = uni.POSICIONES.split(",");
                  for (let x =0;x<posiciones3.length;x++){
                      for (let z =0;z<pos3.length;z++){
                          if (posiciones3[x] == pos3[z]){
                        
                              this.filter_player.push(this.jugadores[i]);
                          }
                      }
                  }
            }
         
        }

        this.filter_player = [...new Set(this.filter_player)];

      },
      filtrar_jugador(){
        this.filter_uni=[];
        var play = [];

        for (let i = 0; i < this.jugadores.length; i++){
          if(this.jugador==this.jugadores[i].NOMBRE){
            play = this.jugadores[i];
          }
        }
        this.actual_play=play;
   
        var A = ["D1", "D2", "NAIA", "JUCO"];
        var B = ["D2", "NAIA", "JUCO"];
        var C = ["NAIA", "JUCO"];
        var D = ["JUCO"];

        var budgetA = ["A", "B", "C", "D"];
        var budgetB = ["B", "C", "D"];
        var budgetC = ["C", "D"];
        var budgetD = ["D"];

       

        for (let i = 0; i < this.universidades.length; i++){
          var nivel = this.universidades[i].DIVISIÓN;
          var level = [];
          if (A.includes(nivel)){
              level.push("A");
          }
          if(B.includes(nivel)){
              level.push("B");
          }
          if (C.includes(nivel)){
              level.push("C");
          }
          if (D.includes(nivel)){
              level.push("D");
          }

          var budget = [];
           if (play.BUDGET == "A"){
              budget = budgetA;
          }
          else if( play.BUDGET == "B"){
              budget = budgetB;
          }
          else if (play.BUDGET == "C"){
              budget = budgetC;
          }
          else{
              budget = budgetD;
          }

         
              if (level.includes(play.NIVEL) && budget.includes(this.universidades[i].BUDGET)){
               
                var posiciones3 = this.universidades[i].POSICIONES.split(",");
               
                var pos3 = play.POSICIONES.split(",");
                for (let x =0;x<posiciones3.length;x++){
                    for (let z =0;z<pos3.length;z++){
                        if (posiciones3[x] == pos3[z]){                          
                            this.filter_uni.push(this.universidades[i]);
                        }
                    }
                }
            }
           
        }
        this.filter_uni = [...new Set(this.filter_uni)];


      },
      seleccionaruniversidad(){
        for(var x=0;x<this.universidades_copia.length;x++){
          if(this.universidad==this.universidades_copia[x].NOMBRE){
            this.universidades=this.universidades_copia[x];
            break;
          }
        }

        for(var i=0;i<this.universidades.length;i++){
          this.universidad=this.universidades[i].NOMBRE;
          this.filtrar_universidad();
          this.universidades[i].JUGADORES = this.filter_player.length;
        }

        this.filter_player=[];

        this.unico=1;
      },
      seleccionarjugador(){
        for(var x=0;x<this.jugadores_copia.length;x++){
          if(this.jugador==this.jugadores_copia[x].NOMBRE){
            this.jugadores=this.jugadores_copia[x];
            break;
          }
        }

        for(var i=0;x<this.jugadores.length;x++){
          this.jugador=this.jugadores[i].NOMBRE;
          this.filtrar_jugador();
          this.jugadores[i].UNIVERSIDADES = this.filter_uni.length;
        }

        this.filter_uni=[];

        this.unico=1;
      },
      async start(doc,creds){
        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();
        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }
        this.universidades = rows;

        const sheet1 = doc.sheetsByIndex[1];
        const rows1 = await sheet1.getRows();
        this.jugadores = rows1;


        for (let i = 0; i < this.universidades.length; i++){
          this.uni_name.push(this.universidades[i].NOMBRE);
        }


         for (let i = 0; i < this.jugadores.length; i++){
           if (this.jugadores[i].STATUS!=1){
          this.player_name.push(this.jugadores[i].NOMBRE);
           }
        }

        var jugadores1=[]
         for (let i = 0; i < this.jugadores.length; i++){
           if (this.jugadores[i].STATUS!=1){
            jugadores1.push(this.jugadores[i]);
           }
        }

        this.jugadores=jugadores1;


        this.uni_name = this.uni_name.sort();
        this.player_name = this.player_name.sort();

        for(var i=0;i<this.universidades.length;i++){
          this.universidad=this.universidades[i].NOMBRE;
          this.filtrar_universidad();
          this.universidades[i].JUGADORES = this.filter_player.length;
        }
        this.filter_player=[];
        this.universidad="";


        for(var x=0;x<this.jugadores.length;x++){
          this.jugador=this.jugadores[x].NOMBRE;
          this.filtrar_jugador();
          this.jugadores[x].UNIVERSIDADES = this.filter_uni.length;
        }
        this.filter_uni=[];
        this.jugador="";

        this.universidades_copia=this.universidades;
        this.jugadores_copia=this.jugadores;
        
      },
    }
  }
</script>
<style scoped lang="scss">
.contenedor1{
  display: flex;
}


.izquierda{
  flex: 1;
}

.derecha{
  flex: 1;
}

.card{
  float: right;
  margin-right: 10px;
}

.card1{
  margin-left: 10px;
}


.boton{
  margin-bottom: 10px;
}

.selector{
  margin-bottom: 15px;
}

.item{
  margin-top: 15px;
}

.linear{
  display: inline-block;
  padding: 10px;
}

.linear1{
  display: inline-block;
}

.flex {
  flex-grow: 0;
}


.flex-empty {
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  
  div {
    width: 150px;
  }
}
</style>