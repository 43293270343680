<template>
  <v-app class="app">
    <v-app-bar
      app
      color=#0061EF
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://admsportsgroup.com/wp-content/uploads/2021/03/logohead-w-1.png"
          transition="scale-transition"
          width="40"
        />

      
      </div>

      <v-spacer></v-spacer>

      <span class="mr-2">Version 2.0</span>
      
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',


  data: () => ({
  }),

  mounted(){
    this.$router.push('Login');
  }
};
</script>
<style scoped lang="scss">

</style>
