import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Main from  '../components/Main.vue'
import InfoUni from '../components/InfoUni.vue'
import InfoPla from '../components/InfoPla.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Main',
    name: 'Main',
    component: Main,
    meta:{
      private:true
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/InfoUni',
    name: 'InfoUni',
    component: InfoUni,
    meta:{
      private:true
    }
  },

  {
    path: '/InfoPla',
    name: 'InfoPla',
    component: InfoPla,
    meta:{
      private:true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
