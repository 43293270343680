<template>
  <v-container>
    <div class="my-2">
            <v-btn
              color="error"
              dark
              large
              @click="volver()"
            >
              Volver
            </v-btn>
          </div>
     <v-layout wrap justify-space-around>
        <v-flex>
          <v-card
          width="300px"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                Jugador
              </div>
              <v-list-item-title class="text-h5 mb-1">
                {{ this.jugadores.NOMBRE }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
            
            >
            <v-img  :src="this.jugadores.FOTO"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              @click="goInfoPla()"
            >
              Hay {{ this.jugadores.UNIVERSIDADES }} universidades
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-flex>
      </v-layout>

      <v-card max-width="95%" class="mx-auto">
        <v-list>
          <v-list-item
            v-for="item in this.filter_uni"
            :key="item.NOMBRE"
            
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.NOMBRE"></v-list-item-title>
              <div class="line">
              <div class="linear">
                <v-list-item-title class="linear1">     División: </v-list-item-title>
                <v-list-item-title v-text="item.DIVISIÓN" class="linear1"></v-list-item-title>
              </div>
              <div class="linear">
                <v-list-item-title class="linear1">     Posiciones: </v-list-item-title>
                <v-list-item-title v-text="item.POSICIONES" class="linear1"></v-list-item-title>
              </div>

              <div class="linear">
                <v-list-item-title class="linear1">     Presupuesto: </v-list-item-title>
                <v-list-item-title v-text="item.BUDGET" class="linear1"></v-list-item-title>
              </div>

              <div class="linear">
                <v-list-item-title class="linear1">     GPA: </v-list-item-title>
                <v-list-item-title v-text="item.GPA" class="linear1"></v-list-item-title>
              </div>

              <div class="linear">
                <v-list-item-title class="linear1">     TOEFL: </v-list-item-title>
                <v-list-item-title v-text="item.TOEFL" class="linear1"></v-list-item-title>
              </div>

              <div class="linear">
                <v-list-item-title class="linear1">     SAT: </v-list-item-title>
                <v-list-item-title v-text="item.SAT" class="linear1"></v-list-item-title>
              </div>
              </div>
              
            </v-list-item-content>

            <v-list-item-avatar>
              <v-img :src="item.LOGO"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </v-card>
       
  </v-container>
</template>

<script>
  export default {
    name: 'Info',
    data: () => ({
      jugadores:[],
      universidades:[],
      jugadores_copia:[],
      universidades_copia:[],
      filter_uni:[],
      actual_play:[],
    }),
    mounted(){
      this.universidades=this.$route.params.universidades;
      this.jugadores=this.$route.params.jugadores;
      this.universidades_copia=this.$route.params.universidades_copia;
      this.jugadores_copia=this.$route.params.jugadores_copia;
      this.filtrar_jugador();
    },
    methods: {
      volver(){
        this.$router.push({ name: "Main", params:{universidades: this.universidades_copia, jugadores: this.jugadores_copia}})
      },
      filtrar_jugador(){
        this.filter_uni=[];
        var play = this.jugadores;

        
        this.actual_play=play;
   
        var A = ["D1", "D2", "NAIA", "JUCO"];
        var B = ["D2", "NAIA", "JUCO"];
        var C = ["NAIA", "JUCO"];
        var D = ["JUCO"];

        var budgetA = ["A", "B", "C", "D"];
        var budgetB = ["B", "C", "D"];
        var budgetC = ["C", "D"];
        var budgetD = ["D"];

       

        for (let i = 0; i < this.universidades.length; i++){
          var nivel = this.universidades[i].DIVISIÓN;
          var level = [];
          if (A.includes(nivel)){
              level.push("A");
          }
          if(B.includes(nivel)){
              level.push("B");
          }
          if (C.includes(nivel)){
              level.push("C");
          }
          if (D.includes(nivel)){
              level.push("D");
          }

          var budget = [];
           if (play.BUDGET == "A"){
              budget = budgetA;
          }
          else if( play.BUDGET == "B"){
              budget = budgetB;
          }
          else if (play.BUDGET == "C"){
              budget = budgetC;
          }
          else{
              budget = budgetD;
          }

         
              if (level.includes(play.NIVEL) && budget.includes(this.universidades[i].BUDGET)){
               
                var posiciones3 = this.universidades[i].POSICIONES.split(",");
               
                var pos3 = play.POSICIONES.split(",");
                for (let x =0;x<posiciones3.length;x++){
                    for (let z =0;z<pos3.length;z++){
                        if (posiciones3[x] == pos3[z]){                          
                            this.filter_uni.push(this.universidades[i]);
                        }
                    }
                }
            }
           
        }
        this.filter_uni = [...new Set(this.filter_uni)];


      },
    }
  }
</script>
<style scoped lang="scss">
.contenedor1{
  display: flex;
}


.izquierda{
  flex: 1;
}

.derecha{
  flex: 1;
}

.card{
  float: right;
  margin-right: 10px;
}

.card1{
  margin-left: 10px;
}


.boton{
  margin-bottom: 10px;
}

.selector{
  margin-bottom: 15px;
}

.item{
  margin-top: 15px;
}

.linear{
  display: inline-block;
  padding: 10px;
}

.linear1{
  display: inline-block;
}

.flex {
  flex-grow: 0;
}


.flex-empty {
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  
  div {
    width: 150px;
  }
}
</style>